<template>
  <div>
    <v-select
      v-if="
        permittedRoles &&
        computedRole &&
        permitted('UserRole.Update') &&
        isSuperAndIsLower
      "
      v-on:change="(v) => updateUserRole(v)"
      v-model="computedRole"
      item-text="name"
      item-value="userRoleId"
      :items="permittedRoles"
      :disabled="!permitted('UserRole.Update')"
      :label="$t('user.role')"
    ></v-select>
    <span class="text-overline" v-else>{{ getNameForRole(computedRole) }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "RoleSelector",

  props: {
    modelValue: {
      default: "",
    },

    userId: {
      default: null,
    },
  },

  model: {
    prop: "modelValue",
  },

  data() {
    return {
      roleValue: "",
    };
  },

  computed: {
    ...mapState("roles", ["roles"]),
    ...mapGetters("roles", ["permittedRoles"]),

    computedRole: {
      get: function () {
        return this.modelValue ? this.modelValue : "";
      },

      set: function (v) {
        this.$emit("input", v);
      },
    },

    isSuperAndIsLower() {
      if (this.permitted("SuperAdmin")) return true;

      var foundRole = this.roles.find((e) => e.userRoleId == this.computedRole);
      if (foundRole && foundRole.defaultRoleKey == "SuperAdmin") return false;

      return true;
    },
  },

  methods: {
    ...mapActions("users", ["updateRole"]),

    getNameForRole(roleId) {
      let res = this.roles.find((d) => d.userRoleId == roleId);
      if (res == null) return "";

      return res.name;
    },

    async updateUserRole(item) {
      await this.updateRole({ role: item, targetUserId: this.userId });
    },
  },

  async created() {
    this.userRole = this.modelValue;
  },
};
</script>